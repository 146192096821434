label.file-input input {
  opacity: 0;
  position: absolute;
}

label.file-input span {
  margin-right: 1em;
}

label.file-input span:before {
  content: attr(data-button-text);
  display: inline-flex;
  margin-right: 1em;
  align-items: stretch;
  justify-content: center;
  border-radius: var(--sl-input-border-radius-medium);
  border-style: solid;
  border-width: var(--sl-input-border-width);
  background-color: var(--sl-color-neutral-0);
  border-color: var(--sl-color-neutral-300);
  color: var(--sl-color-neutral-700);
  padding: 0 1.25em;
  font-size: var(--sl-button-font-size-medium);
  height: var(--sl-input-height-medium);
  line-height: calc(
    var(--sl-input-height-medium) - var(--sl-input-border-width) * 2
  );
  font-family: var(--sl-input-font-family);
  font-weight: var(--sl-font-weight-semibold);
  white-space: nowrap;
  vertical-align: middle;
}

.tedivo-form {
  .form-fields-row {
    display: flex;
    flex-wrap: wrap;
    gap: var(--sl-spacing-large);
    margin: var(--sl-spacing-large) 0;

    & > * {
      flex: 1 0 15%;
    }

    &:first-child {
      margin-top: 0;
    }

    &.title {
      flex-direction: column;
    }

    &.hidden {
      display: none;
    }

    @media screen and (max-width: 2200px) {
      & > * {
        flex: 1 0 20%;
      }
    }

    @media screen and (max-width: 1024px) {
      & > * {
        flex: 1 0 40%;
      }
    }

    @media screen and (max-width: 660px) {
      flex-direction: column;
    }
  }

  &.form-boxed {
    border: solid var(--sl-panel-border-width) var(--sl-panel-border-color);
    border-radius: var(--sl-border-radius-medium);
    padding: var(--sl-spacing-large);
  }

  &.max500px {
    max-width: 500px;
  }

  .subtitle {
    margin: 0 !important;
  }

  &.tvd-form-margin-top {
    margin-top: var(--sl-spacing-large);
  }

  .form-buttons {
    text-align: right;
  }
}

.has-error {
  &:not([disabled])::part(form-control-label),
  &:not([disabled])::part(form-control-help-text) {
    color: var(--sl-color-danger-700);
  }

  &:not([disabled])::part(base),
  &:not([disabled])::part(combobox) {
    border-color: var(--sl-color-danger-600);
  }

  &:focus-within::part(base),
  &:focus-within::part(combobox) {
    box-shadow: 0 0 0 var(--sl-focus-ring-width) var(--sl-color-danger-200);
  }
}
