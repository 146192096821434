@import "styles/variables.scss";
@import "styles/common-components.scss";
@import "styles/tables.scss";
@import "styles/forms.scss";

html {
  -webkit-text-size-adjust: 100%;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 1.5;
  tab-size: 4;
  scroll-behavior: smooth;
  margin: 0;
}

body {
  font-family: inherit;
  line-height: inherit;
  margin: 0;
  color: var(--sl-color-neutral-900);
  overflow-x: auto;
  //overflow-y: scroll;
}

#unsupported-browser {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
}

.warning-text {
  color: var(--sl-color-warning-700);
}

h1 {
  margin-top: 0;
}

h1,
h2,
h3 {
  font-weight: var(--sl-font-weight-semibold);
}

a {
  color: var(--sl-color-primary-600);
  text-decoration: none;

  &:visited {
    color: var(--sl-color-primary-500);
  }

  &.success {
    color: var(--sl-color-success-600);
  }
}

.login-button-home {
  margin-top: 2em;
}

.submit-button-below {
  margin-top: var(--sl-spacing-large);
}

.user-options-holder {
  display: flex;
  gap: 1em;
  flex-wrap: wrap;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }

  sl-radio-group {
    flex: 1 1 auto;

    sl-radio {
      margin-bottom: 0.125em;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .pillLcgCannotUse,
  .tcgDirectionMeaning {
    display: inline-block;
    max-width: 16em;
    font-size: 80%;
    margin: 1em 0 0 0;
    color: var(--sl-color-neutral-600);
  }

  .pillLcgCannotUse {
    color: var(--sl-color-orange-700);
  }
}

.formated-json-switch {
  margin-top: 1em;
}

.main-edit-drawer {
  &::part(footer),
  &::part(header) {
    box-shadow: 0 0 10px 4px var(--sl-color-neutral-100);
  }

  &.fix-overflow::part(panel) {
    overflow-y: hidden;
  }

  .drawer-disabled-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--sl-color-neutral-200);
    opacity: 0.75;
    z-index: 10;
    display: none;
  }

  .extra-buttons-drawer {
    display: flex;
    gap: 0.5em;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
  }

  .tedivo-form h2.subtitle {
    font-size: 1.2em;
  }

  &.do-not-select-panel {
    &::part(panel) {
      user-select: none;
      -webkit-user-select: none;
    }
  }
}

tvd-top-tools-component units-scroller-element {
  margin-right: 1em;
}

.info-div {
  margin: 1em 0 2em 0;
  max-width: 80ch;
  font-size: 90%;
  color: var(--sl-color-neutral-800);
}

units-scroller-element.block {
  border: 1px dotted var(--sl-color-neutral-300);
  border-radius: 30px;
  padding: 4px 14px 5px;
  background-color: var(--sl-color-neutral-100);
}

.integrated-dialog {
  .dialog-buttons-holder {
    display: flex;
    gap: 0.5em;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
}

.fixed_dialog__overlay {
  z-index: var(--sl-z-index-tooltip, 1000);
  position: fixed;
  inset: 0px;
  background-color: var(--sl-overlay-background-color);

  display: none;
  justify-content: center;
  align-items: center;
}

.ghost-occupied {
  fill: orange;
  stroke: red;
}

.text-with-separation {
  margin: 0 0.75em;
  display: inline-block;
}

code {
  font-family: "Courier New", Courier, monospace;
}

.missing-cgs-dialog {
  h3:first-child {
    margin-top: 0;
  }
  .table-missing-cgs {
    tr {
      td:first-child {
        min-width: 17ch;
        padding-left: 1em;
        color: var(--sl-color-neutral-700);
      }
      td.warning {
        color: var(--sl-color-warning-700);
      }
    }
  }
}

.max-height-400 {
  max-height: 600px;
}

.width-100percent {
  width: 100%;
  height: auto;
}

.higher-modal-z-index {
  &::part(base) {
    z-index: 801;
  }
}

.oss-password-compliance-container {
  strong {
    display: block;
    margin-bottom: 0.5em;
    font-weight: normal;
  }

  .oss-password-compliance {
    margin: 0 0 1em 0;
    padding: 0;
    list-style: none;
    font-size: var(--sl-font-size-small);
    color: var(--sl-color-success-700);

    .password-schema-wrong {
      color: var(--sl-color-neutral-500);
    }
  }
}

.tvd-cgs-holder {
  margin-left: 0.5em;

  .tvd-cgs-dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background-color: var(--sl-color-neutral-100);
    margin-left: 0.125em;

    &.green {
      background-color: var(--sl-color-success-500);
    }

    &.orange {
      background-color: var(--sl-color-orange-500);
    }

    &.yellow {
      background-color: var(--sl-color-yellow-500);
    }
  }
}

.text-danger {
  color: var(--sl-color-danger-700);
}

small.filesCount {
  font-size: 60%;
  font-weight: normal;
  color: var(--sl-color-neutral-600);
}

.grecaptcha-badge {
  width: 70px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
  left: 4px !important;
  bottom: 100px !important;
}
.grecaptcha-badge:hover {
  width: 256px !important;
}

/* Logos */
.logo-light {
  display: inline-block !important;
}
.logo-dark {
  display: none !important;
}

.sl-theme-dark {
  .logo-light {
    display: none !important;
  }
  .logo-dark {
    display: inline-block !important;
  }
}

sl-menu-item {
  &.danger {
    &::part(base) {
      color: var(--sl-color-danger-600);
    }
  }

  &.warn {
    &::part(base) {
      color: var(--sl-color-warning-600);
    }
  }
}

.tvd-view-tvl-iframe {
  width: 100%;
  height: 80vh;
}

.slot-actions {
  display: flex;
  gap: 0.5em;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
}

tvd-vote-tvl-component {
  sl-button::part(base) {
    min-width: 120px;
  }
}

// .sl-scroll-lock {
//   padding-right: var(--sl-scroll-lock-size, 14px);
//}

.fileHistory {
  margin-bottom: 1em;
}

.modal-column-selector {
  display: flex;
  gap: 0.5em 1.25em;
  flex-wrap: wrap;
}

.add-note-container {
  display: flex;
  gap: 1em;
  align-items: flex-start;
  justify-items: flex-start;

  .add-note-list {
    padding-top: 0;
    flex: 1 0 auto;
    order: 2;
  }

  .add-note-header {
    flex: 0 1 350px;
    flex-direction: column;
    order: 1;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1em;

    margin-bottom: 1em;

    sl-textarea {
      width: 100%;
    }
  }
}

.spacer {
  flex-grow: 2;
}

delete-button {
  margin-left: 0.5em;
}

sl-dialog {
  &::part(header-actions) {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    font-size: var(--sl-font-size-medium);
  }
}

.small-info {
  font-size: 80%;
  color: var(--sl-color-neutral-600);
  text-align: center;
  max-width: 54ch;
  margin: 0 auto;
}

.fixed-width-for-bays {
  flex: 0 0 100px;
}

.z-index-10000 {
  z-index: var(--sl-z-index-dialog, 1000) !important;
}

.space-below {
  margin-bottom: var(--sl-spacing-large, 1.5em);
}

.back-to-top {
  position: sticky;
  z-index: 1;
  bottom: 1em;
  left: 100%;

  --offset: 100px;
  margin-top: calc(100vh + var(--offset));
}

.actions-holder {
  display: flex;
  gap: 0.5em;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
