.sections-oss-card section,
.oss-card {
  background-color: var(--sl-color-neutral-0);
  border-radius: var(--sl-input-border-radius-medium);
  border: solid var(--sl-panel-border-width) var(--sl-panel-border-color);
  padding: var(--sl-spacing-large);
  margin-bottom: var(--sl-spacing-medium);
  overflow: visible;

  h2:first-child {
    margin-top: 0;
  }

  h3:first-child {
    margin-top: 0;
  }

  &.oss-card-inline {
    display: inline-flex;
  }
}

.oss-centered-card {
  background-color: var(--sl-color-neutral-0);
  padding: var(--sl-spacing-large);
  border: solid var(--border-width) var(--border-color);
  border-radius: var(--sl-input-border-radius-medium);
  max-width: 50%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);

  @media screen and (max-width: 600px) {
    position: relative;
    top: auto;
    max-width: 100%;
    transform: translateX(-50%);
  }
}

tf-file {
  display: inline-block;
  border: 1px solid transparent;
  border-radius: var(--sl-input-border-radius-medium);

  &:invalid {
    color: var(--sl-color-danger-800);
  }

  &:focus {
    background-color: var(--sl-input-background-color-focus);
    border-color: var(--sl-input-border-color-focus);
    box-shadow: var(--sl-focus-ring);
    outline: none;
  }

  &:hover {
    background-color: var(--sl-input-background-color-hover);
    border-color: var(--sl-input-border-color-hover);
  }
}

dictionary-display-element {
  display: flex;
  flex-wrap: wrap;
  gap: 1em 3em;

  .break {
    flex-basis: 100%;
    height: 0;
  }

  .dd-label {
    display: block;
    font-size: 70%;
    text-transform: uppercase;
    color: var(--sl-color-neutral-600);
    border-bottom: 1px dotted var(--sl-color-neutral-200);
  }

  .warning .dd-value {
    color: var(--sl-color-warning-700);
  }

  .warning-strong {
    .dd-label {
      color: var(--sl-color-warning-700);
    }
    .dd-value {
      font-weight: bold;
    }
  }
}

title-with-actions-component {
  display: flex;
}

sl-drawer units-scroller-element::part(holder) {
  margin-bottom: 1.5em;
}

sl-radio-group.with-fieldset::part(form-control),
fieldset {
  border: solid var(--sl-panel-border-width) var(--sl-panel-border-color);
  border-radius: var(--sl-border-radius-medium);
  padding: var(--sl-spacing-large);
  padding-top: var(--sl-spacing-x-small);

  legend {
    font-family: var(--sl-input-font-family);
    font-size: var(--sl-input-font-size-medium);
    font-weight: var(--sl-input-font-weight);
    color: var(--sl-input-color);
    padding: 0 var(--sl-spacing-small);
    margin-bottom: var(--sl-spacing-small) !important;
  }
}

sl-radio-group.with-fieldset {
  &::part(form-control) {
    border: solid var(--sl-panel-border-width) var(--sl-panel-border-color);
    border-radius: var(--sl-border-radius-medium);
    padding: var(--sl-spacing-large);
    padding-top: var(--sl-spacing-x-small);
  }

  &::part(form-control-label) {
    font-family: var(--sl-input-font-family);
    font-size: var(--sl-input-font-size-medium);
    font-weight: var(--sl-input-font-weight);
    color: var(--sl-input-label-color);
    padding: 0;
    margin-bottom: var(--sl-spacing-small) !important;
  }
}

sl-spinner {
  font-size: 1.5rem;
}

link-element {
  &.white {
    a {
      color: var(--sl-color-neutral-100);
    }
  }

  &.danger {
    a {
      color: var(--sl-color-danger-600);
    }
  }

  &.warning {
    a {
      color: var(--sl-color-warning-600);
    }
  }

  &.success {
    a {
      color: var(--sl-color-success-600);
    }
  }
}
