.tvd-table {
  border-collapse: collapse;
  margin-bottom: 1em;
  border: 1px solid var(--sl-color-neutral-400);

  .tvd-table {
    margin-bottom: 0;
  }

  th {
    background-color: var(--sl-color-neutral-300);
    border: 1px solid var(--sl-color-neutral-400);
    font-weight: normal;
    height: var(--sl-spacing-3x-large);
    padding: 0 0.5em;
    padding: 0 8px;
    min-width: 3.5em;

    small {
      color: var(--sl-color-neutral-700);
    }

    &.small-padding {
      padding: 0 2px;
    }
  }

  &.tvd-table-sticky-caption {
    caption {
      position: sticky;
      z-index: 2;
      top: 0;
      background-color: var(--sl-color-neutral-0);
    }

    thead th {
      position: sticky;
      top: calc(3em - 1px);
      z-index: 1;
      border: 1px solid var(--sl-color-neutral-400);
    }
  }

  &.tvd-table-sticky {
    thead th {
      position: sticky;
      top: -21px;
      z-index: 1;
      border: 1px solid var(--sl-color-neutral-400);
    }
  }

  &.tvd-table-sticky-0 {
    thead th {
      top: -1px;
    }
  }

  &.tvd-table-tr-valign-middle {
    td {
      vertical-align: middle;
    }
  }

  tr.warning {
    color: var(--sl-color-warning-700);
  }

  tr.commonRowInfo td {
    background-color: var(--sl-color-neutral-200);
  }

  tr.commonRowInfo td.above-padded {
    vertical-align: top;
    padding-top: 2em;
  }

  tr:not(.commonRowInfo):hover {
    background-color: var(--sl-color-neutral-100);
  }

  tr[data-row-selected] {
    background-color: var(--sl-color-primary-100);

    &:hover {
      background-color: var(--sl-color-primary-100);
    }
  }

  td {
    border: 1px solid var(--sl-color-neutral-400);
    padding: 5px 8px;
    min-width: 3.5em;
    vertical-align: top;

    &.centered {
      text-align: center;
    }

    &.right-aligned {
      text-align: right;
    }

    &.emphasized {
      font-style: italic;
    }

    &.v-align-middle {
      vertical-align: middle;
    }

    &.small-padding {
      padding: 5px 2px;
    }

    &.state-success {
      color: var(--sl-color-success-700);
    }
  }

  tf-input-padded,
  tf-input-units,
  sl-input {
    display: inline-block;
    width: 6em;
  }

  span {
    display: inline-block;
    border-width: 0;
    border-style: solid;
    border-color: transparent;

    &.view-vcgFields-bottomBase {
      border-bottom-color: var(--sl-color-primary-300);
    }

    &.view-maxHeight {
      border-bottom-color: var(--sl-color-green-300);
    }

    &.general-grid-tiers {
      border-bottom-color: var(--sl-color-neutral-500);
    }

    &.view-tcg {
      border-bottom-color: var(--sl-color-orange-400);
    }

    &.view-edit-editCGs-masterTcgsAbove {
      border-bottom-color: var(--sl-color-orange-300);
    }

    &.view-edit-editCGs-masterTcgsBelow {
      border-bottom-color: var(--sl-color-red-300);
    }

    &.view-edit-editCGs-masterBottomBases {
      border-bottom-color: var(--sl-color-sky-300);
    }
  }

  th span {
    border-bottom-width: 2px;
  }
  td span {
    border-bottom-width: 1px;
  }

  td small {
    font-size: 70%;
    color: var(--sl-color-neutral-600);
  }

  .select-checkbox-no-label sl-checkbox::part(label) {
    display: none;
  }
}

.tvd-table-smart-table {
  width: 100%;
  margin-bottom: 0;
  position: relative;

  caption {
    padding-bottom: 0.5em;

    .top-actions {
      display: flex;
    }

    .smart-table-caption-content {
      display: flex;
      gap: 0.5em;

      & > * {
        flex: 0 1 auto;
      }

      h2 {
        margin: 0 auto;
        text-align: left;

        small {
          display: inline-block;
        }
      }

      .spacer {
        flex-grow: 2;
      }

      @media screen and (max-width: 1000px) {
        flex-wrap: wrap;
        h2 {
          flex: 1 0 100%;
        }
      }
    }
  }

  &.margin-bottom {
    margin-bottom: 1em;
  }

  &.margin-top {
    margin-top: 1em;
  }
  &.margin-top-2 {
    margin-top: 2em;
  }

  .smart-table-search {
    display: inline-block;

    .smart-table-search-box sl-input {
      width: auto;
      text-overflow: ellipsis;

      @media screen and (max-width: 1000px) {
        width: auto;
      }
    }

    .smart-table-search-results {
      display: none !important;
    }
  }

  .sort-by,
  .no-sort-by {
    margin: 0;
    padding: 0;
    border: none;
    background: transparent;
    color: var(--sl-color-neutral-900);
    font-size: var(--sl-font-size-medium);
  }

  .sort-by {
    .sort-icons {
      display: inline-block;
      margin: 0 0 0 0.25em;
      width: 20px;
      height: 16px;
      vertical-align: bottom;
      pointer-events: none;

      .icon-asc-holder,
      .icon-desc-holder {
        display: none;
        width: 16px;
        height: 16px;
      }
    }

    &.sorted-by-asc .sort-icons .icon-asc-holder {
      display: inline-block;
    }

    &.sorted-by-desc .sort-icons .icon-desc-holder {
      display: inline-block;
    }

    &:hover .sort-icons .icon-asc-holder {
      color: var(--sl-color-primary-700);
      display: inline-block;
    }

    &.sorted-by-asc:hover .sort-icons {
      .icon-asc-holder {
        display: none;
      }
      .icon-desc-holder {
        color: var(--sl-color-primary-700);
        display: inline-block;
      }
    }

    &.sorted-by-desc:hover .sort-icons {
      .icon-desc-holder {
        display: none;
      }
      .icon-asc-holder {
        color: var(--sl-color-primary-700);
        display: inline-block;
      }
    }

    &.sort-by-secondary {
      font-size: 80%;
    }
  }

  &.just-sorted {
    .sort-by {
      pointer-events: none;
    }
  }

  &.auto-width {
    width: auto;
  }

  &.min-width-100-ch {
    width: 100ch;
  }

  &.small-title {
    caption {
      h2 {
        font-size: 1em;
      }
    }
  }

  .table-action-box {
    display: flex;
    gap: 0.5em;
  }

  .td-smt-selector {
    text-align: center;
    vertical-align: middle !important;

    sl-checkbox {
      margin-right: 0.5em;

      &::part(label) {
        display: none;
      }
    }
  }
}
